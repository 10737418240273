<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import AppIcon, {
    IconColor,
    IconName,
    IconSize,
} from "@/Components/Shared/icon/AppIcon.vue";

export type ListItemVariant = "withLabel" | "zebra";

type Props = {
    icon?: IconName;
    iconColor?: IconColor;
    iconSize?: IconSize;
    variant?: ListItemVariant;
};

const {
    icon = undefined,
    iconColor = undefined,
    iconSize = undefined,
    variant = undefined,
} = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "leading-tight flex gap-1",
    variants: {
        variant: {
            withLabel:
                "flex flex-col sm:flex-row sm:[&>*:first-child]:w-[140px] sm:[&>*:first-child]:break-words gap-1 sm:gap-4",
            zebra: "odd:bg-slate-50 px-2 py-1",
        },
    },
};

const { aClass } = installAntlerComponent("list__item", { variant }, classes);
</script>

<template>
    <li
        :class="aClass()"
        data-list-item
    >
        <div
            v-if="icon"
            class="flex gap-2 grow"
        >
            <AppIcon
                v-if="icon"
                :name="icon"
                :size="iconSize"
                :color="iconColor"
                class="mt-1"
            />

            <slot />
        </div>
        <slot v-else />
        <slot name="after" />
    </li>
</template>
