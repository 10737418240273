<script setup lang="ts">
import { useAttrs } from "vue";
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";

export type FormFieldAs = "div" | "label";

type Props = {
    as?: FormFieldAs;
    hasErrors?: boolean;
    hasIcon?: boolean;
    hasLabel?: boolean;
};

const { as = "div" } = defineProps<Props>();
const attrs = useAttrs();
const classes: AntlerClasses<Props> = {
    base: "grow w-full flex flex-col",
};
const { aClass } = installAntlerComponent("form-field", {}, classes, attrs);
</script>

<template>
    <component
        :is="as"
        :class="aClass({ 'text-red-500': hasErrors })"
    >
        <slot />
    </component>
</template>
