<script setup lang="ts">
import { useAttrs } from "vue";
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";

export type FormFieldLabelAs = "div" | "label";
export type FormFieldLabelSize = "large";

type Props = {
    as?: FormFieldLabelAs;
    size?: FormFieldLabelSize;
    required?: boolean;
};

const { as = "label", size = undefined } = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "relative flex mb-1 font-medium text-base text-slate-700 inline-flex hyphens-auto",
    variants: {
        size: {
            large: "text-md",
        },
    },
};
const attrs = useAttrs();
const { aClass } = installAntlerComponent(
    "form-field-label",
    { size },
    classes,
    attrs,
);
</script>

<template>
    <component
        :is="as"
        :class="aClass()"
    >
        <slot />
        <span v-if="required">*</span>
    </component>
</template>
