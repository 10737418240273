import { IconColor, IconName } from "@/Components/Shared/icon/AppIcon.vue";

export type defaultInputProps = {
    disabled?: boolean;
    autocomplete?: string;
    readonly?: boolean;
    icon?: IconName;
    iconColor?: IconColor;
    loading?: boolean;
    placeholder?: string;
    required?: boolean;
    label?: string | number;
    errors?: string | string[] | object;
    prefix?: string;
    type?: string;
    hint?: string;
    name: string;
    modelValue?: string | number | any[] | object;
};

export const defaultFormEmits = ["blur", "focus", "update:modelValue"];
