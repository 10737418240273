<script setup lang="ts">
import { useAttrs } from "vue";
import {
    AntlerClasses,
    installAntlerComponent,
    responsive,
} from "@/Utils/component";
import { Responsive } from "@/types/responsive";

export type ListAs = "ul" | "ol";
export type ListVariant = Responsive<"inline"> | "bordered";
export type ListGap = "large" | "regular" | "none";

type Props = {
    as?: ListAs;
    variant?: ListVariant;
    gap?: ListGap;
};

const {
    as = "ul",
    variant = undefined,
    gap = "regular",
} = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "flex flex-col",
    variants: {
        variant: {
            inline: responsive("flex flex-row items-start shrink-0"),
            bordered:
                "border border-gray-300 [&_[data-list-item]]:p-4 [&_[data-list-item]]:border-b",
        },
        gap: {
            large: "gap-4",
            regular: "gap-2",
            none: "gap-0",
        },
    },
    combinedVariants: [
        {
            variant: "inline",
            gap: "regular",
            classes: "gap-y-0.5 gap-x-2",
        },
        {
            variant: "round",
            size: "small",
            classes: "h-4 w-4",
        },
        {
            variant: "ghost",
            color: "default",
            classes: "bg-transparent",
        },
    ],
};
const attrs = useAttrs();
const { aClass } = installAntlerComponent(
    "list",
    { variant, gap },
    classes,
    attrs,
);
</script>

<template>
    <component
        :is="as"
        :class="aClass()"
        data-list
    >
        <slot />
    </component>
</template>
